/* 1: konglomerat.css, 2: bootstrap.css, 3: app.css*/

/* inject:css */
/* endinject */

/* app.css --- CUSTOM CSS for the app .... oh boy this stinks .... :( */

h1, h2, h3, h4 {
  font-weight: bold!important;
}


.ff-bruger-vogn-accordion  .panel, .ff-bruger-vogn-accordion  .panel-title {
  font-size: 12px;
}

.ff-bruger-vogn-accordion form .row {
  min-height: 20px;
}

.ff-bruger-vogn-accordion .panel-heading {
  min-height: 20px;
  padding-top: 3px;
  padding-bottom: 0;
}

.ff-bruger-vogn-accordion form .row > div.col-sm-6 {
  /*border: 1px solid #2aabd2!important;*/
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.1!important;
}

.ff-bruger-vogn-accordion form .row input {
  padding: 0 8px;
  margin-top: -1px;
  margin-bottom: 0;
  line-height: 1.1!important;
}

.ff-edit {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.ff-view {
  border-color: transparent;
}

.ff-bruger-vogn-accordion input[type='text'][readonly],
.ff-bruger-vogn-accordion input[type='password'][readonly],
.ff-bruger-vogn-accordion input[type='email'][readonly]{
  cursor: text;
}

.ff-bruger-vogn-accordion input[type='checkbox'][disabled] {
  cursor: pointer;
}


.ff-bruger-vogn-accordion input[type='checkbox'] {
  position: relative;
  font-weight: normal;
  top: 2px;
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 2px;
}

.ff-bruger-vogn-accordion input[type='checkbox'] + label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
  padding-right: 12px;
  margin-bottom: 0;
  margin-top: 4px;
}

.ff-required {
  position: absolute;
  display: inline;
  right: 2px;
  top: 2px;
  font-weight: bolder;
  color: red;
}

.ff-password {
  position: absolute;
  display: inline;
  right: 16px;
  top: 0;
  font-size: 12px;
  font-weight: normal;
  color: black;
}


.container-fluid {
  line-height: 1.1!important;
}
